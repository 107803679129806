import React from 'react'
import { Text, CFView } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from 'emotion'

const LocationSelectNameView = ({ selectedLocationName }) => (
  <CFView className={styles.location}>
    <FontAwesomeIcon icon="map-marker" className={styles.icon} />
    <Text h2 heading2 black>
      {selectedLocationName}
    </Text>
    <FontAwesomeIcon icon="chevron-down" className={styles.icon} />
  </CFView>
)

const styles = {
  location: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    transition: 'all .2s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  icon: css({
    marginLeft: '1rem',
    marginRight: '1rem',
    color: 'black',
    fontSize: '2.5rem',
  }),
}

export default LocationSelectNameView
