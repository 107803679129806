import React, { Component } from 'react'
import { css } from 'emotion'
import { promotions, gallery } from 'images'
import { MEDIA_QUERY } from 'styles'

class Promotions extends Component {
  render() {
    return (
      <div className={styles.container}>
        <img src={promotions} className={styles.section} alt="About" />
        <img src={gallery} className={styles.section} alt="Food Gallery" />
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5rem',
    maxWidth: '130rem',
    [MEDIA_QUERY.MD]: {
      padding: '1rem',
    },
  }),
  section: css({
    marginTop: '5rem',
    marginBottom: '5rem',
    objectFit: 'contain',
    width: '95%',
    [MEDIA_QUERY.MD]: {
      marginTop: '0',
      marginBottom: '5em',
    },
  }),
}

export default Promotions
