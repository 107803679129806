import React from 'react'
import { css } from 'emotion'
import PageFooter from './PageFooter'
import Hero from './Hero'
import Promotions from './Promotions'
import Contact from './Contact'
import { homebg } from 'images'

class HomeView extends React.Component {
  render() {
    const {
      HoursElement,
      ContactUsElement,
      OrderNowButtonElement,
      LogoElement,
      locationId,
    } = this.props
    return (
      <section className={styles.container}>
        <Hero OrderNowButtonElement={OrderNowButtonElement} />
        <div className={styles.BGcontainer}>
          <div className={styles.innerContainer}>
            <Promotions locationId={locationId} />
            <Contact
              HoursElement={HoursElement}
              LogoElement={LogoElement}
              ContactUsElement={ContactUsElement}
              locationId={locationId}
            />
          </div>
          <PageFooter />
        </div>
      </section>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
  }),
  BGcontainer: css({
    background: `url(${homebg}) no-repeat`,
    backgroundSize: 'cover',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  innerContainer: css({
    width: '100%',
    maxWidth: '130rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
}

export default HomeView
