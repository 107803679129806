import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, Text } from 'components'
import { cflogo } from 'images'
import { MEDIA_QUERY } from 'styles'

class PageFooter extends Component {
  render() {
    return (
      <div className={styles.container}>
        <Text h6 color="black" content>
          COPYRIGHT © 2019 CODEFUSION INC.
        </Text>
        <CFLink href="https://codefusion.tech">
          <img className={styles.image} src={cflogo} alt="CodeFusion" />
        </CFLink>
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '7.5rem',
    paddingLeft: '4rem',
    paddingRight: '4rem',
    [MEDIA_QUERY.SM]: {
      height: '6rem',
    },
  }),
  image: css({
    height: '2.5rem',
    objectFit: 'contain',
    paddingTop: '.5rem',
  }),
}

export default PageFooter
