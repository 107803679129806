import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView } from 'components'
import HomeHeader from './HomeHeader'
import { hero, downloadButton, heroText, doordash } from 'images'
import { MEDIA_QUERY } from 'styles'

class Hero extends Component {
  render() {
    const { OrderNowButtonElement } = this.props
    return (
      <div className={styles.container}>
        <HomeHeader />
        <div className={styles.heroTextContainer}>
          <img
            src={heroText}
            alt="Hoke Poke Now Taking Online Orders"
            className={styles.heroText}
          />
          <CFView className={styles.buttons}>
            {OrderNowButtonElement}
            <CFLink href="http://onelink.to/6em6bs">
              <img
                src={downloadButton}
                alt="Download App"
                className={styles.downloadButton}
              />
            </CFLink>
          </CFView>
        </div>
        <CFLink href="https://order.online/store/the-hoke-poke-151520">
          <img
            className={styles.doordashButton}
            src={doordash}
            alt="Door Dash"
          />
        </CFLink>
      </div>
    )
  }
}
const styles = {
  container: css({
    height: '100vh',
    width: '100%',
    background: `url(${hero}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    [MEDIA_QUERY.MD]: {
      backgroundAttachment: 'scroll',
      height: '100vh',
      backgroundPosition: '40%',
    },
  }),
  heroTextContainer: css({
    width: '45%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    [MEDIA_QUERY.MD]: {
      top: '20%',
      left: '1%',
      width: '100%',
    },
  }),
  heroText: css({
    width: '100%',
  }),
  buttons: css({
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    transform: 'translate(35%, -125%)',
  }),
  downloadButton: css({
    paddingTop: '3%',
    width: '100%',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  doordashButton: css({
    height: '6rem',
    position: 'absolute',
    right: '5rem',
    bottom: '4rem',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    [MEDIA_QUERY.MD]: {
      position: 'absolute',
      height: '5rem',
      bottom: '4rem',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  }),
}

export default Hero
