import React from 'react'
import { CFView, Text } from 'components'
import { css } from 'emotion'
import { logo } from 'images'
import { MEDIA_QUERY } from 'styles'

export default ({
  EmailInputElement,
  CancelButtonElement,
  SubmitButtonElement,
}) => (
  <div className={styles.container}>
    <CFView column w="100%">
      <CFView column w="100%" center mb="2rem">
        <img src={logo} alt="Hoke Poke" height="80" />
      </CFView>
      <CFView mb="2rem">
        <Text heading2 h3 dark center>
          Please enter your email to send password reset:
        </Text>
      </CFView>
      <CFView mb="2rem">{EmailInputElement}</CFView>
      <CFView row justifyBetween mt="2rem">
        {CancelButtonElement}
        {SubmitButtonElement}
      </CFView>
    </CFView>
  </div>
)

const styles = {
  container: css({
    backgroundColor: 'white',
    border: '3px solid #000',
    color: 'white',
    padding: '4rem',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '48rem',
    [MEDIA_QUERY.SM]: {
      padding: '2rem',
      width: '100%',
    },
  }),
}
