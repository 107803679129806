import React from 'react'
import { CFLink, CFView, Text } from 'components'

export default ({ address, phoneNumber, email }) => (
  <CFView column>
    {address && (
      <CFView row w="100%">
        <CFView w="9rem" mb="1rem">
          <Text heading h4 dark>
            Address
          </Text>
        </CFView>
        <CFLink href="https://goo.gl/maps/6hJ4YRYGvcmTTD8Y7">
          <Text heading h4 dark>
            {address}
          </Text>
        </CFLink>
      </CFView>
    )}
    {phoneNumber && (
      <CFView row w="100%" mb="1rem">
        <CFView w="9rem">
          <Text heading h4 dark>
            Phone
          </Text>
        </CFView>
        <Text heading h4 dark>
          {phoneNumber}
        </Text>
      </CFView>
    )}
    {email && (
      <CFView row w="100%" mb="1rem">
        <CFView w="9rem">
          <Text heading h4 dark>
            Email
          </Text>
        </CFView>
        <Text heading h4 dark>
          {email}
        </Text>
      </CFView>
    )}
  </CFView>
)
