import React, { Component } from 'react'
import { css } from 'emotion'
import { CFSelect, CFLink, CFView, Space, Text } from 'components'
import { appStore, googlePlay, locationMap, locationMap2 } from 'images'
import { MEDIA_QUERY } from 'styles'
import { dispatch } from 'cf-web-app/store'

class Contact extends Component {
  render() {
    const { HoursElement, ContactUsElement } = this.props
    return (
      <CFView column bg="rgba(255,255,255,.7)" w="90%" pv="2rem">
        <div className={styles.container}>
          <div className={styles.innerContainer1}>
            <div className={styles.column}>
              <Text heading h2 bold color="black">
                CONTACT US
              </Text>
              <Space h1 />
              {ContactUsElement}
              <Space h1 />
              <Text heading h2 bold color="black">
                DOWNLOAD APP
              </Text>
              <Space h1 />
              <CFView w="30rem">
                <Text h6 dark>
                  Hoké Poké is now taking online orders. Download our app at:
                </Text>
              </CFView>
              <Space h1 />
              <CFView row>
                <div className={styles.buttonBar}>
                  <CFLink href="https://apps.apple.com/us/app/the-hoke-poke/id1485524524">
                    <img
                      className={styles.mobile}
                      src={appStore}
                      alt="App Store"
                    />
                  </CFLink>
                  <Space w2 />
                  <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.hokepoke">
                    <img
                      className={styles.mobile}
                      src={googlePlay}
                      alt="Goodle Play"
                    />
                  </CFLink>
                </div>
              </CFView>
            </div>
            <Space h2 />
            <div className={styles.column}>
              <Text heading h2 bold color="black">
                BUSINESS HOURS
              </Text>
              <Space h1 />
              <div className={styles.hours}>{HoursElement}</div>
            </div>
          </div>
          <Space h1 w2 />
          <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
            {locationId => (
              <CFLink
                href={
                  locationId === '2a8HdKLiCGbFB9g3JXAF'
                    ? 'https://goo.gl/maps/6hJ4YRYGvcmTTD8Y7'
                    : 'https://goo.gl/maps/wbzV7VUL1TnLm2fY7'
                }
                className={styles.innerContainer2}
              >
                <img
                  src={
                    locationId === '2a8HdKLiCGbFB9g3JXAF'
                      ? locationMap
                      : locationMap2
                  }
                  alt="Hoke Poke Map"
                  className={styles.locationMap}
                />
              </CFLink>
            )}
          </CFSelect>
        </div>
      </CFView>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    maxWidth: '130rem',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '4rem',
    [MEDIA_QUERY.LG]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: 0,
      textAlign: 'center',
    },
  }),
  innerContainer1: css({
    width: '55%',
    display: 'flex',
    justifyContent: 'center',
    [MEDIA_QUERY.LG]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  }),
  innerContainer2: css({
    width: '45%',
    [MEDIA_QUERY.LG]: {
      width: '100%',
    },
  }),
  locationMap: css({
    width: '90%',
    objectFit: 'contain',
    [MEDIA_QUERY.LG]: {
      marginTop: '2rem',
    },
  }),
  hours: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '35rem',
  }),
  mobile: css({
    height: '3.5rem',
    objectFit: 'contain',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  column: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [MEDIA_QUERY.MD]: {
      alignItems: 'center',
    },
  }),
  buttonBar: css({
    display: 'flex',
  }),
  icon: css({
    height: '3.5rem',
    width: '3.5rem',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
  border: css({
    width: '100%',
    [MEDIA_QUERY.MD]: {
      objectFit: 'cover',
    },
  }),
}

export default Contact
