import React from 'react'
import { CFView, Text } from 'components'
import { css } from 'emotion'
import { pointsMeter, rewardbg, rewardDefault } from 'images'

const RewardsLoadedView = ({
  pointsMeterWidth,
  imageUrl,
  LeftArrowButtonElement,
  RightArrowButtonElement,
  points,
  maxPoints,
  requiredPoints,
  name,
  RedeemRewardButtonElement,
}) => (
  <div className={styles.rewardContainer}>
    <div className={styles.reward}>
      {imageUrl ? (
        <img className={styles.image} src={imageUrl} alt="Reward" />
      ) : (
        <img
          className={styles.rewardDefault}
          src={rewardDefault}
          alt="Reward"
        />
      )}
    </div>
    {LeftArrowButtonElement}
    {RightArrowButtonElement}
    <div className={styles.meter}>
      <div
        className={styles.progress}
        style={{
          width: `${pointsMeterWidth * 32.4}rem`,
          transition: 'width 2s',
        }}
      >
        <img className={styles.pointsMeter} src={pointsMeter} alt="Meter" />
      </div>
    </div>
    <div className={styles.pointsText}>
      <Text h6 bold black>
        {points} / {maxPoints}
      </Text>
    </div>
    <Text bold light className={styles.requiredPoints}>
      {requiredPoints === 0 ? 'FREE' : `${requiredPoints} Points`}
    </Text>
    <CFView className={styles.name}>
      <Text bold light center>
        {name ? name.toUpperCase() : ''}
      </Text>
    </CFView>
    <CFView className={styles.redeemButton}>{RedeemRewardButtonElement}</CFView>
  </div>
)

const styles = {
  rewardContainer: css({
    borderRadius: '1rem',
    position: 'relative',
    height: '100%',
    width: '100%',
    background: `url(${rewardbg}) no-repeat`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '2px 2px 8px rgba(0,0,0,.5)',
  }),
  reward: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  image: css({
    position: 'relative',
    width: '15rem',
    height: '16.8rem',
    left: '.1rem',
    top: '.9rem',
    boxShadow: '0 0 8px rgba(0,0,0,.5)',
  }),
  rewardDefault: css({
    position: 'relative',
    objectFit: 'contain',
    width: '16rem',
    top: '1rem',
    left: '.5rem',
  }),
  requiredPoints: css({
    fontSize: '2.2rem',
    textShadow: '1px 1px 4px rgba(0,0,0,.3)',
    textTransform: 'uppercase',
    position: 'absolute',
    top: '10rem',
  }),
  name: css({
    width: '100%',
    fontSize: '2.2rem',
    textShadow: '1px 1px 4px rgba(0,0,0,.3)',
    position: 'absolute',
    bottom: '11.5rem',
    verticalAlign: 'top',
    textAlign: 'center',
  }),
  redeemButton: css({
    position: 'absolute',
    bottom: '3rem',
  }),
  meter: css({
    position: 'absolute',
    top: '2.4rem',
    left: '2.1rem',
  }),
  progress: css({
    overflow: 'hidden',
  }),
  pointsMeter: css({
    objectFit: 'contain',
    width: '32.4rem',
  }),
  pointsText: css({
    position: 'absolute',
    top: '4.5rem',
    right: '1.9rem',
  }),
}

export default RewardsLoadedView
