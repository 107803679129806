import React from 'react'
import { CFView, Text } from 'components'
import { logo } from 'images'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

const ReviewModalView = ({
  ReviewItemListElement,
  CommentInputElement,
  SkipButtonElement,
  SubmitButtonElement,
}) => (
  <div className={styles.container}>
    <CFView column w="100%">
      <CFView column w="100%" center mb="2rem">
        <img src={logo} alt="Hoke Poke" height="80" />
      </CFView>
      <Text heading2 h2 black center>
        TELL US HOW WE DID!
      </Text>
      <CFView column mv="2rem">
        {ReviewItemListElement}
      </CFView>
      {CommentInputElement}
    </CFView>
    <CFView row mt="2rem">
      {SkipButtonElement}
      <CFView w="2rem" />
      {SubmitButtonElement}
    </CFView>
  </div>
)

const styles = {
  container: css({
    backgroundColor: 'white',
    border: '3px solid #000',
    color: 'white',
    padding: '4rem',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '53rem',
    [MEDIA_QUERY.SM]: {
      padding: '2rem',
      width: '100%',
    },
  }),
  productItem: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  }),
  count: css({
    minWidth: '3rem',
  }),
  name: css({
    flex: 1,
  }),
}

export default ReviewModalView
